import "./Home.css";

import NavBar from "./components/navbar/navbar";
import FirstPage from "./components/firstPage/firstPage";
import On from "./components/on/on";
import Impact from "./components/impact/impact";
import Guidelines from "./components/guidelines/guidelines";
import RealCases from "./components/realCases/realCases";
import Project from "./components/project/project";
import Footer from "./components/footer/footer";
import Contact from "./components/contact/contact";
import Services from "./components/services/services";
import SnackbarProvider from 'react-simple-snackbar'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

const Home = () => {
  return (
    <SnackbarProvider>
      <div className="main-container">
        <NavBar />
        <FirstPage />
        <div className="screen">
          <div className="main-content"></div>
          <On />
          <Impact />
          <Guidelines />
          <RealCases />
          <Services />
          <Project />
          <Contact />
          <Footer />
        </div>
      </div>
    </SnackbarProvider>
  );
};

export default Home;
