import "./navbar.css";
import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarLink,
  MDBCollapse
} from 'mdb-react-ui-kit';

const NavBar = () => {
  const [showNavSecond, setShowNavSecond] = useState(false);
  
  return (
    
    <MDBNavbar expand='lg' light>
      <MDBContainer fluid>
        <MDBNavbarToggler
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNavSecond(!showNavSecond)}
        >
          <svg height="48" width="48" fill="#fff"><path d="M5.15 37.4V32.7H42.85V37.4ZM5.15 26.35V21.65H42.85V26.35ZM5.15 15.3V10.55H42.85V15.3Z"/></svg>
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavSecond}>
          <MDBNavbarNav>
            <MDBNavbarLink href='#on'> Sobre </MDBNavbarLink>
            <MDBNavbarLink href='#impact'> Impactos </MDBNavbarLink>
            <MDBNavbarLink href='#guidelines'> Diretrizes </MDBNavbarLink>
            <MDBNavbarLink href='#real-cases'> Casos Reais </MDBNavbarLink>
            <MDBNavbarLink href='#project'> Projetos </MDBNavbarLink>
            <MDBNavbarLink href='#contact'> Contato </MDBNavbarLink>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default NavBar;