import "./guidelines.css";
import { useState } from "react";

const list_guidelines = [
  {
    title: "Eficiência",
    text: "O uso eficiente dos recursos naturais será o fator chave para mitigação dos impactos ambientais e sociais negativos dos sistemas econômico. Promovemos soluções que na essência buscam a maximização da eficiência, que vão desde do uso de genética animal aplicada ao design de engenharias para maximização do uso de recursos energéticos.",
  },
  {
    title: "Capacitação",
    text: 'Acreditamos em um processo permanente e deliberado de aprendizagem, com o propósito de contribuir para o desenvolvimento de competências institucionais por meio do desenvolvimento de competências individuais. Por isso a ideia do "engagement" como objetivos de sustentabilidade, comunicando de forma clara e transparente ao nosso público, usando nossa posição e alcance para interagir com nossos clientes e assim aumentar a conscientização sobre os temas ligados a sustentabilidade, problemas ambientais.',
  },
  {
    title: "Aderência Economia Circular",
    text: "Nossas soluções buscam aderência a economia circular buscando associar desenvolvimento econômico a um melhor uso de recursos naturais, por meio de novos modelos de negócios e da otimização nos processos de fabricação com menor dependência de matéria-prima virgem, priorizando insumos mais duráveis, recicláveis e renováveis. Queremos repensar a forma de desenhar, produzir e comercializar produtos para garantir o uso e a recuperação inteligente dos recursos naturais, um aperfeiçoamento do sistema econômico atual, que visa um novo relacionamento com os recursos naturais e a sua utilização pela sociedade. É uma proposta de adição e retenção de valor dos recursos, e regeneração do meio ambiente, que busca produzir sem esgotar os recursos naturais, e sem poluir o meio ambiente, consequentemente, preservando o nosso planeta.",
  },
  {
    title: "Cooperativismo",
    text: "Buscamos promover um movimento econômico e social, entre pessoas, em que a cooperação se baseia na participação dos associados, nas atividades econômicas com vistas a atingir o bem comum e promover uma reforma social dentro do capitalismo. Por meio da cooperação, busca-se satisfazer as necessidades humanas e resolver os problemas comuns. O fim maior é o homem, não o lucro",
  },
  {
    title: "Qualidade de vida",
    text: 'Buscamos promover através de nossas soluçoes qualidade de vida com o aprimoramento do nível das condições básicas e suplementares do ser humano. Estas condições envolvem desde o bem-estar físico, mental, psicológico e emocional, os relacionamentos sociais, como família e amigos, e também a saúde, a educação e outros parâmetros que afetam a vida humana.',
  },
  {
    title: "Desenvolvimento Integral",
    text: "Analisamos, de forma mais profunda e abrangente, a magnitude da diversidade de situações complexas a serem enfrentadas e a multidisciplinariedade de competências que requerem. O desenvolvimento sustentável pleno e integral é o principio para o estabelecimento de um processo decisório de quem foca na geração de valor e na rentabilidade compensatória, além de retribuir reconhecimento e recompensas para investidores, sociedade, parceiros e colaboradores.",
  },
  {
    title: "Preservação e Recuperação Solo",
    text: "Compactação, erosão, salinização e poluição química são apenas alguns problemas que tornam o solo inviável para a atividade agrícola. Esses fatores podem ser causados por chuvas, manejo de irrigação inadequado, pisoteio do gado e uso descontrolado de fertilizantes e defensivos. Diante desse cenário, exige-se que sejam aplicadas ações de recuperação do solo para garantir a produtividade agrícola no país. Nossas soluções passam pelo uso responsável do solo com a utilização da pecuária como fonte de não degradação | recuperação do solo.",
  },
  {
    title: "Bem estar animal",
    text: 'Um dos conceitos mais populares de bem–estar animal foi dado por Barry Hughes que o define como "um estado de completa saúde física e mental, em que o animal está em harmonia com o ambiente que o rodeia" (Hughes, 1976). Outra definição foi dada por Broom (1986) em que o bem-estar animal é definido pela "sua capacidade em se adaptar ao seu meio ambiente". Nossa essência vem do desenvolvimento de uma Pecuária Sustentável que tem por base a proteção não só ambiental e social, mas principalmente a proteção do bem estar dos animais.',
  },
  {
    title: "Rastreabilidade",
    text: "Entendemos como fator fundamental para o desenvolvimento sustentável o uso da tecnologia para rastreabiliadde dos sistemas e seus impactos nos escopos 1, 2 e 3. Com a utilização do conceito de rastreabilidade integral, promovemos em nossas soluções um alto grau de confiabilidade do sistema como um todo.",
  },
  {
    title: "Reuso Materiais",
    text: "O reaproveitamento de materiais é um dos fundamentos básicos do desenvolvimento sustentável e por base buscamos em nossas soluções entender os processos produtivos para buscar a maximização de toda cadeia de valor . Seja através dos dejetos orgânicos e ou resíduos sólidos buscaremos prover soluções para maximizar não só o uso mas as receitas e minimizar os custos.",
  }
];

const Guidelines = () => {
  const [activeBtn, setActiveBtn] = useState(3);

  return (
    <>
      <div id="guidelines" className="page_guidelines">

        <div className="texts_guidelines">
          <h1 className="title_section">Nossas <br /> Diretrizes</h1>
          <span>{list_guidelines[activeBtn].title}</span>
          <p>{list_guidelines[activeBtn].text}</p>
        </div>

        <div className="circles-container">
          <div 
            onClick={() => setActiveBtn(0)}
            className="ball ball1"
            style={{opacity: `${activeBtn === 0 ? 1 : 0.5}`}}
          > Eficiência </div>

          <div 
            onClick={() => setActiveBtn(1)}
            className="ball ball2"
            style={{opacity: `${activeBtn === 1 ? 1 : 0.5}`}}
          > Capacitação </div>

          <div 
            onClick={() => setActiveBtn(2)}
            className="ball ball3"
            style={{opacity: `${activeBtn === 2 ? 1 : 0.5}`}}
          > Aderência <br /> Economia Circular </div>

          <div 
            onClick={() => setActiveBtn(3)}
            className="ball ball4"
            style={{opacity: `${activeBtn === 3 ? 1 : 0.5}`}}
          > Cooperativismo </div>

          <div 
            onClick={() => setActiveBtn(4)}
            className="ball ball5"
            style={{opacity: `${activeBtn === 4 ? 1 : 0.5}`}}
          > Qualidade <br /> de vida </div>

          <div 
            onClick={() => setActiveBtn(5)}
            className="ball ball6"
            style={{opacity: `${activeBtn === 5 ? 1 : 0.5}`}}
          > Desenvolvimento <br /> Integral </div>

          <div 
            onClick={() => setActiveBtn(6)}
            className="ball ball7"
            style={{opacity: `${activeBtn === 6 ? 1 : 0.5}`}}
          > Preservação e <br /> Recuperação Solo </div>

          <div 
            onClick={() => setActiveBtn(7)}
            className="ball ball8"
            style={{opacity: `${activeBtn === 7 ? 1 : 0.5}`}}
          > Bem estar <br /> animal </div>

          <div 
            onClick={() => setActiveBtn(8)}
            className="ball ball9"
            style={{opacity: `${activeBtn === 8 ? 1 : 0.5}`}}
          > Rastreabilidade </div>

          <div 
            onClick={() => setActiveBtn(9)}
            className="ball ball10"
            style={{opacity: `${activeBtn === 9 ? 1 : 0.5}`}}
          > Reuso <br /> Materiais </div>
        </div>
      </div>
    </>
  );
};

export default Guidelines;
