import "./contact.css";
import api from "../../services/api";
import image from "../../assets/images/contato.png";
import { useState } from "react";
import { useSnackbar } from 'react-simple-snackbar'

const Contact = () => {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: 'white',
      color: 'black',
      zIndex: 9999
    },
    closeStyle: {
      color: 'black',
    },
  })

  const createContact = () => {
    if(email === "" || message === "") {
      openSnackbar('Por favor, preencha todos os campos.', [8000])
      return
    }
    else {
      let content = new FormData();

      content.append("email", email);
      content.append("message", message);
  
      const config = {
        headers: { "content-type": "multipart/json" },
      };
  
      api
        .post("core/contact/", content, config)
        .then((response) => {
          openSnackbar('Contato enviado com sucesso!', [8000])
          setEmail("")
          setMessage("")
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  
  return (
    <div id="contact" className="page_contact">
      <div className="contact-page-content">
        <div className="card-input">
          <h1 className="title">Entre em <br /> contato</h1>

          <span>Email</span>
          <input
            value={email}
            type="text" 
            placeholder="Email" 
            onChange={(e) => setEmail(e.target.value)}
          />

          <span>Mensagem</span>
          <textarea 
            value={message}
            placeholder="Mensagem" 
            rows="5" 
            cols="50"
            onChange={(e) => setMessage(e.target.value)}>
          </textarea>

          <button onClick={createContact}>Enviar</button>
        </div>
        
        <div className="image">
          <img src={image} alt="Imagem contato"/>
        </div>
      </div>
    </div>
  );
};

export default Contact;
