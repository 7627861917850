import "./footer.css";
import logo from "../../assets/images/logo_marrom.png";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-top">
          <div className="logo-footer">
            <img src={logo} alt="Logo" />
          </div>

          <div className="list_options">
            <a href="#on">Sobre</a>

            <div className="card_col">
              <a href="#impact">Impactos</a>
              <a className="sub_itens" href="#social">
                Social
              </a>
              <a className="sub_itens" href="#environmental">
                Ambiental
              </a>
            </div>

            <a href="#guidelines">Diretrizes</a>
            <a href="#guidelines">Serviços</a>
            <a href="#real-cases">Recuperação Solos</a>
            <a href="#project">Captar Agro</a>
            <a href="#contact">Contato</a>
          </div>
        </div>

        <div className="footer_bottom">
          <p>&copy; All Rights Reserved Captar Solutions 2022 <br />
            Designed by Amorfus Partners | NOCLAF Design Created by NOCLAF</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
