import "./realCases.css";
import realCases from "../../assets/images/pessoas.png";

const RealCases = () => {
  return (
    <>
      <div id="real-cases" className="real-cases">
        <div className="card_text_hover">
          <p> Missão </p>
          <p> Ser+Humano </p>
        </div>
        <img src={realCases} alt="Casos reais" />
      </div>
    </>
  );
};

export default RealCases;
