import "./impact.css";
import { useState, useEffect } from "react";

import ambiental from "../../assets/images/ambiental.png";
import social from "../../assets/images/social.png";

const Impact = () => {

  const [screen, setScreen] = useState('');


  useEffect(() => {
    console.log(screen);
  }, [screen]);

  return (
    <>
      <div className="card_info_row card_mobile">
        <div className="page_impact">

          <div id="environmental" className="card_left" onClick={() => setScreen('Ambiente')}>
            <span>Ambiental</span>
            <img src={ambiental} alt="ambiental" />
          </div>

          <div className="card_info">
            <h2> Impacto <br /> Ambiental </h2>
            <p direction="right">
              Herdamos da Captar Agrobusiness nossa incansável busca pelo desenvolvimento sustentável, que encontra as necessidades socio-economicas das sociedadessem comprometer a habilidade das futuras gerações de atender suas próprias necessidades.  <br /><br />
              Propomos em nossas soluções um alinhamento economico ambiental aonde a pobreza e a desigualdade sejam contempladas como algo a ser observado com as sociedades buscando, pelo aumento do potencial produtivo como pela garantia de oportunidades iguais para todos, harmonizar seus modelos econômicos ao ambiente, que sustentem portanto a vida na Terra: a atmosfera, as águas, os solos e os seres vivos.”  <br /><br />
              Na sua essência, o desenvolvimento sustentável é um processo de mudança no qual a exploração dos recursos, o direcionamento dos investimentos, a orientação do desenvolvimento tecnológico e a mudança institucional estão em harmonia e reforçam o atual e futuro potencial para satisfazer as aspirações e necessidades humanas.
            </p>
          </div>

          <div id="social" className="card_right" onClick={() => setScreen('Social')}>
            <span>Social</span>
            <img src={social} alt="social" />
          </div>

          <div className="card_info">
            <h2> Impacto <br /> Social </h2>
            <p direction="left">
              O Social está em nosso DNA. Nascido no semiárido brasileiro, entendemos o Social como o maior fomentador de riqueza de uma economia, unindo a produtividade ao propósito! <br /><br />
              Buscamos contribuir através de nossas soluções para uma sociedade mais justa, informada e igualitária, fomentando novos propósitos como individuo e sociedade. <br /><br />
              Com nossos projetos procuramos impactar de forma positiva a realidade das pessoas em torno daquele proposito, produto ou serviço. São objetivos claros, transparentes e mensuráveis para o agora e para o amanhã, missões e visões que partilham o desenvolvimento social da nossa sociedade.
            </p>
          </div>

        </div>

      </div>

      <div id="impact" className="div-impact card_web">

        {screen !== 'Social' && 
          <div className="card_left d-flex card-absolute-left" onClick={() => setScreen('Ambiente')}>
            {screen === '' && <span>Ambiental</span>}
            <img src={ambiental} alt="ambiental" />
          </div>
        }

        <div className="card_left card_info" style={screen === 'Social' ? {} : { visibility: 'hidden' }}>
          <div className="back" onClick={() => setScreen('')}>
            <svg height="40" width="40"><path d="M17.792 37.833 -0.042 20 17.792 2.167 21.5 5.875 7.375 20 21.5 34.125Z" /></svg>
          </div>
          <h2> Impacto <br /> Social </h2>
          <p>
            O Social está em nosso DNA. Nascido no semiárido brasileiro, entendemos o Social como o maior fomentador de riqueza de uma economia, unindo a produtividade ao propósito! <br /><br />
            Buscamos contribuir através de nossas soluções para uma sociedade mais justa, informada e igualitária, fomentando novos propósitos como individuo e sociedade. <br /><br />
            Com nossos projetos procuramos impactar de forma positiva a realidade das pessoas em torno daquele proposito, produto ou serviço. São objetivos claros, transparentes e mensuráveis para o agora e para o amanhã, missões e visões que partilham o desenvolvimento social da nossa sociedade.
          </p>
        </div>

        {screen !== 'Ambiente' && 
          <div className="card_right d-flex card-absolute-right" onClick={() => setScreen('Social')}>
            {screen === '' && <span>Social</span>}
            <img src={social} alt="social" />
          </div>
        }

        <div className="card_right card_info" style={screen === 'Ambiente' ? {} : { visibility: 'hidden' }}>
          <div className="back" onClick={() => setScreen('')}>
            <svg height="40" width="40"><path d="M17.792 37.833 -0.042 20 17.792 2.167 21.5 5.875 7.375 20 21.5 34.125Z" /></svg>
          </div>
          <h2> Impacto <br /> Ambiental </h2>
          <p>
            Herdamos da Captar Agrobusiness nossa incansável busca pelo desenvolvimento sustentável, que encontra as necessidades socio-economicas das sociedadessem comprometer a habilidade das futuras gerações de atender suas próprias necessidades.  <br /><br />
            Propomos em nossas soluções um alinhamento economico ambiental aonde a pobreza e a desigualdade sejam contempladas como algo a ser observado com as sociedades buscando, pelo aumento do potencial produtivo como pela garantia de oportunidades iguais para todos, harmonizar seus modelos econômicos ao ambiente, que sustentem portanto a vida na Terra: a atmosfera, as águas, os solos e os seres vivos.”  <br /><br />
            Na sua essência, o desenvolvimento sustentável é um processo de mudança no qual a exploração dos recursos, o direcionamento dos investimentos, a orientação do desenvolvimento tecnológico e a mudança institucional estão em harmonia e reforçam o atual e futuro potencial para satisfazer as aspirações e necessidades humanas.
          </p>
        </div>

      </div>

    </>
  );
};

export default Impact;
