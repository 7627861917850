import "./on.css";
import mockup from "../../assets/videos/Mockup.mp4"
import retangulos from "../../assets/images/retangulos.png"

const On = () => {
  return (
    <>
      <div id="on" className="page_on">
        <div className="card_top">
          <div className="left"> Uma empresa </div>

          <div className="mockup">
            <img src={retangulos} alt="" />
            <video className="page-project-video" autoPlay loop muted>
              <source src={mockup} type="video/mp4" />
            </video>
          </div>

          <div className="right"> de soluções </div>
        </div>

        <div className="card_on_bottom">
          <h1 className="title"> Soluções de Sustentabilidade </h1>

          <div className="description">
            A Captar Solutions é uma empresa de Soluções End-to-End da Captar
            Agrobusiness que promove o social e ambiental através de projetos
            que viabilizam e fomentam a aderência do setor agropecuário à
            Economia Circular de Baixo Carbono. Com soluções e modelos
            proprietários, levamos ao setor o que há de mais moderno no
            mercado para fomentar projetos ligados a sustentabilidade no
            Brasil e exterior.
          </div>
        </div>
      </div>
    </>
  );
};

export default On;
