import "./project.css";
import video from "../../assets/videos/almir.mp4"

const Project = () => {
  return (
    <>
      <div id="project" className="page_project">
        <div className="card_project_top">
          <video className="page-project-video" autoPlay loop muted>
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <div className="card_project_bottom">
          <div className="left">
            <h1 className="title"> Protocolo Captar <br /> Pecuária Sustentável </h1>
          </div>
          
          <div className="right">
            Em conjunto com a Captar Agrobusiness desenvolvemos um modelo inovador de Pecuária Sustentável que busca ser a referência na indústria da pecuária global. Baseado em uma metodologia proprietária e fundamentada por SustainAlytics que comprova sua eficácia, o projeto coloca em posição de destaque a Captar Agrobusiness como referencia no Brasil e Exterior.
          </div>
        </div>
      </div>

    </>
  );
};

export default Project;
