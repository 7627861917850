import "./services.css";
import service1 from "../../assets/images/serviços1.svg";
import service2 from "../../assets/images/serviços2.svg";
import service3 from "../../assets/images/serviços3.svg";
import service4 from "../../assets/images/serviços4.svg";

const Services = () => {
  return (
    <>
      <div id="card_service" className="page-service">
        <span className="title_section">Nossos <br /> Serviços</span>

        <div className="cards">

          <div className="card_service">
            <img src={service1} alt="Casos reais" />
            <h2>Advisory de soluções <br /> pra sustentabilidade</h2>
            <p>
              Fazemos toda a parte de aconselhamento e project design para
              projetos e sistemas de sustentabilidade no setor Agro. Com
              especialidade na Pecuária Sustentável e sistemas de cultivos
              integrados, provemos soluçoes completas para o desenvolvimento de
              projetos de sustentabilidade.
            </p>
          </div>

          <div className="card_service">
            <img src={service2} alt="Casos reais" />
            <h2>Formatação e Aderência <br /> a economia circular</h2>
            <p>
              Promovemos através de Sustainalytics os diagnósticos e projetos
              para aderência a economia circular de baixo carbono de sistemas
              agropecuários.
            </p>
          </div>

          <div className="card_service">
            <img src={service3} alt="Casos reais" />
            <h2>Preverificação de <br /> sistemas</h2>
            <p>
              Fazemos todo o serviço de Preverificação, Verificação e
              Certificação de sistemas Agropecuários gerando a qualificação
              necessária para integração desses sistemas ao fluxo das finaças
              sustentáveis.
            </p>
          </div>

          <div className="card_service">
            <img src={service4} alt="Casos reais" />
            <h2>Soluções de finanças <br /> sustentáveis</h2>
            <p>
              Promovemos soluções na parte de emissão de Títulos Sustentáveis,
              Climáticos, Verdes e ou de Transição além de promover a solução
              completa para emissão de Créditos de Carbono Certificados.
            </p>
          </div>
        </div>
      </div>
      <div className="page-service service-bottom">
        <div className="card_services_bottom">
          <div className="left">
            <h1 className="title"> Busca a descarbonização e <br /> Recuperação Solo Semi Árido </h1>
          </div>
          <div className="right">
          <p>A descarbonização do Agrobusiness é uma tendência mundial no que tange principalmente a busca pela diminuição das emissões de gases de efeito estufa (GEEs). Acreditamos que com a aplicação de técnicas e tecnologia adequada, conseguimos não só promover a descarbonização, mas principalmente preservar solos e nascentes. </p>
          <p>Através do manejo e técnicas de cultivo | produção adequados, conseguimos aumentar a capacidade de estoque de carbono no solo e com isso gerar receitas extras para reinvestimento em um agrobusiness mais sustentável. A descarbonização e recuperação de solos degradados não são novidade, nem para a agricultura | pecuária e nem para a pesquisa científica nacional. Muito já vem sendo feito nesse sentido, e nós da Captar Solutions temos as soluções para sua implementação.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
