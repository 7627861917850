import "./firstPage.css";
import video from "../../assets/videos/video_fundo.mp4";
import logo from "../../assets/images/logo.svg";
import descend from "../../assets/images/descer.svg";

const FirstPage = () => {
  return (
    <>
      <div className="first-page">
        <video className="first-page-video video-backgroud" autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
        <div className="logo">
        <img src={logo} alt="Logo" />
        </div>

        <div className="circle_descend">
          <a href="#on">
            <img src={descend} alt="Logo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default FirstPage;